const token = localStorage.getItem("token");
const loggedInNavItems = document.querySelectorAll(".nav-item--logged-in");
const loggedOutNavItems = document.querySelectorAll(".nav-item--logged-out");

if (token === null) {
    // Not logged in
    loggedOutNavItems.forEach((element) => {
        element.classList.add("nav-item--show");
    });
} else {
    // Logged in
    loggedInNavItems.forEach((element) => {
        element.classList.add("nav-item--show");
    });
}
